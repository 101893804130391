<div class="plans-container">
    <div class="tabs">
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link" (click)="changeTab('active')" [class.active]="activeTab === 'active'">Planes activos <span class="length-plans">{{dataLengths[0]}}</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="changeTab('draft')" [class.active]="activeTab === 'draft'">Borradores <span class="length-plans">{{dataLengths[1]}}</span></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="changeTab('finished')" [class.active]="activeTab === 'finished'">Finalizados <span class="length-plans">{{dataLengths[2]}}</span></a>
          </li>
        </ul>
      </div>
</div>
<div class="slide-details container">
    <nav>
      <a (click)="changeTab('active')" [class.selected-option]="activeTab === 'active'"></a>
      <a (click)="changeTab('draft')" [class.selected-option]="activeTab === 'draft'"></a>
      <a (click)="changeTab('finished')" [class.selected-option]="activeTab === 'finished'"></a>
      <div class="animation"
        [ngClass]="{'start-movements': activeTab === 'active', 'start-advances': activeTab === 'draft', 'start-benefits': activeTab === 'finished'}">
      </div>
    </nav>
  </div>
