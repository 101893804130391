import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AdvancesAvailableModel } from '../../../../../../../domain/models/advance-available.model';
import { ProductModel } from '../../../../../../../domain/models/product.model';
import { GetCatalogAdvancesAvailableUseCase } from '../../../../../../../domain/usecases/catalog/get-catalog-advances-available.usecase';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css'],
  standalone: true,
  imports: [CommonModule],
})
export class TableAdvanceAvailableComponent implements OnInit {
  @Input() product?: ProductModel;
  advancesAvailable: AdvancesAvailableModel[] = [];
  constructor(
    private getCatalogAdvancesAvailableUseCase: GetCatalogAdvancesAvailableUseCase
  ) {}
  ngOnInit(): void {
    if (this.product && this.product.savingsPlan) {
      this.getCatalogAdvancesAvailableUseCase
        .execute({ savingsPlanId: this.product.savingsPlan.id })
        .subscribe({
          next: (result) => {
            console.log({ result });
            this.advancesAvailable = result.data!;
          },
          error: (err) => {
            this.advancesAvailable = [];
          },
        });
    } else {
      console.error('Product or savingsPlan is undefined.');
    }
  }


}
