import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-users-plans',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './users-plans.component.html',
  styleUrl: './users-plans.component.scss'
})
export class UsersPlansComponent {
  @Input() activeTab: string = "active";
  @Input() dataLengths: [number, number, number] = [0, 0, 0];

  @Output() activeTabChange = new EventEmitter<string>();
  constructor(private router: Router) {}
  changeTab(tab: string) {
    this.activeTabChange.emit(tab);
    this.activeTab = tab;
  }


 nav() {
    this.router.navigate(['/simulator'])
  }
}


