import { UseCase } from '@domain/base/use-case';
import { UserModel } from '@domain/models/user.model';
import { UserRepository } from '@domain/repositories/user.repository';
import { Observable } from 'rxjs';


export class GetUserUseCase
  implements UseCase<{ idUser: string }, UserModel>
{
  constructor(private userRepository: UserRepository) {}

  execute(params: { idUser: string }): Observable<UserModel> {
    return this.userRepository.getUser(params.idUser);
  }
}
