import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { finalize } from 'rxjs';
import { PlanCardComponent } from '../plan-card/plan-card.component';
import {
  TravelPlanShortModel,
  TravelPlanState,
} from '../../../../../../../../domain/models/travel-plan.model';
import { loadTravelPlans } from '../../../../../../core/store/actions/travel-plans.actions';
import { DraftCardsComponent } from '../draft-cards/draft-cards.component';
import { TravelPlansSelectors } from '../../../../../../core/store/selectors/travel-plans.selectors';
import { ActionButtonComponent } from '../../../../../../shared/components/action-button/action-button.component';
import { GetPlansContractSummaryUseCase } from '@domain/usecases/plan-contract/get-plans-summary.usecase';
import { PlanContractSummaryModel } from '@domain/models/plan-contract-summary.model';
import { PlanListItemComponent } from '@shared/components/plan-list-item/plan-list-item.component';

@Component({
  selector: 'app-plans-container',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    PlanCardComponent,
    DraftCardsComponent,
    ActionButtonComponent,
    PlanListItemComponent,
  ],
  templateUrl: './plans-container.component.html',
  styleUrls: ['./plans-container.component.css'],
})
export class PlansContainerComponent implements OnInit {
  @Input() currentTab: string = 'actives';
  @Input() userId: string = '';
  @Output() dataLengths = new EventEmitter<[number, number, number]>();

  lengthDrafts: number = 0;

  isLoading = false;
  data: {
    actives: PlanContractSummaryModel[];
    drafts?: TravelPlanShortModel[];
    finished: PlanContractSummaryModel[];
  } = { actives: [], finished: [] };

  constructor(
    private getPlansSummaryUseCase: GetPlansContractSummaryUseCase,
    private store: Store
  ) {}

  async ngOnInit(): Promise<void> {
    this.data.actives = await this.getPlans(TravelPlanState.Active);
    this.data.finished = await this.getPlans(TravelPlanState.Finished);
    this.store.dispatch(
      loadTravelPlans({ state: TravelPlanState.Draft, userId: this.userId })
    );

    this.store.select(TravelPlansSelectors.selectTravelPlans).subscribe({
      next: (plans) => {
        this.dataLengths.emit([
          this.data.actives.length,
          plans.length,
          this.data.finished.length,
        ]);

        this.data.drafts = plans;
      },
    });
  }

  private getPlans(
    state: TravelPlanState
  ): Promise<PlanContractSummaryModel[]> {
    this.isLoading = true;

    return new Promise((res) => {
      this.getPlansSummaryUseCase
        .execute({ state, userId: this.userId })
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe({
          next: (paginatedData) => {
            res(paginatedData.data ?? []);
          },
          error: () => {
            res([]);
          },
        });
    });
  }
}
