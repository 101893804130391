import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserModel } from '../../../../../../domain/models/user.model';
import { GetUserUseCase } from '../../../../../../domain/usecases/user/get-user.usecase';
import { PageContainerComponent } from '../../../../shared/components/page-container/page-container.component';
import { Breadcrumb } from 'presentation/app/models/breadcrumb';
import { FloatBarComponent } from '../float-bar/float-bar.component';
import { UsersPlansComponent } from './components/users-plans/users-plans.component';
import { PlansContainerComponent } from './components/plans-container/plans-container.component';

@Component({
  selector: 'app-users-details',
  standalone: true,
  imports: [PageContainerComponent,FloatBarComponent, UsersPlansComponent,CommonModule, PlansContainerComponent],
  templateUrl: './users-details.component.html',
  styleUrl: './users-details.component.scss'
})
export class UsersDetailsComponent {
  breadcrumb: Breadcrumb[] = [
    { label: 'Usuarios', path: '/users' },

  ];

  user?: UserModel;
  idUser:string = '';

  currentTab: string = "active";

  constructor(private getProfileData: GetUserUseCase, private route: ActivatedRoute) {
    this.idUser = this.route.snapshot.paramMap.get('userId')!;
  }
  ngOnInit(): void {
    this.getProfileData.execute({idUser:this.idUser}).subscribe({
      next:(profileData)=>{
        this.user=profileData;
        this.breadcrumb.push({label:profileData.firstName +' '+ profileData.lastName, path:''})
      }
    })
  }

  lengthPlans: [number, number, number] = [0, 0, 0];

  // Función para capturar las longitudes de los datos
  updateDataLengths(lengths: [number, number, number]) {
    this.lengthPlans = lengths;
  }
}
