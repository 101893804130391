import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { TableAdvanceAvailableComponent } from '../../../../users/components/table/table.component';
import { TravelPlanModel } from '../../../../../../../../domain/models/travel-plan.model';
import { setTravelPlan, setTravelPlanSubscriptionInfo } from '../../../../../../core/store/actions/travel-plan.actions';
import { PlanCardComponent } from '../../../../../../../../presentation/app/pages/modules/components/users-details/components/plan-card/plan-card.component';

@Component({
  selector: 'app-draft-cards',
  templateUrl: './draft-cards.component.html',
  styleUrls: ['./draft-cards.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    PlanCardComponent,
    TableAdvanceAvailableComponent,
  ],
})
export class DraftCardsComponent {
  @Input() plan?: TravelPlanModel;

  constructor(private router: Router, private store: Store) {}

  initPlanNav(numberContributions: number, savingsPlan: string) {
    if (!this.plan?.id) {
      return;
    }

    this.store.dispatch(setTravelPlan({ travelPlan: this.plan }));
    this.store.dispatch(
      setTravelPlanSubscriptionInfo({
        subscriptionInfo: {
          savingsPlanId: savingsPlan,
          numberContributions: numberContributions,
          firstName: '',
          lastName: '',
          birthdate: '',
          gender: '',
          documentTypeId: '',
          documentNumber: '',
          residenceDepartment: '',
          residenceMunicipality: '',
          phoneNumber: '',
          contribution: {
            amount: 0,
            paymentMethod: '',
            transactionCode: '',
          },
        },
      })
    );

    this.router.navigate(['account/plan/subscribe/create']);
  }

  openAccordionIndex: number = -1;

  toggleAccordion(index: number) {
    if (this.openAccordionIndex === index) {
      this.openAccordionIndex = -1; // Si el mismo acordeón se hace clic de nuevo, ciérralo
    } else {
      this.openAccordionIndex = index;
    }
  }
}
