import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ActionButtonComponent } from '../action-button/action-button.component';
import { TravelPlanShortModel } from '@domain/models/travel-plan.model';

@Component({
  selector: 'app-plan-list-item',
  standalone: true,
  imports: [CommonModule, RouterModule, ActionButtonComponent],
  templateUrl: './plan-list-item.component.html',
  styleUrls: ['./plan-list-item.component.scss'],
})
export class PlanListItemComponent {
  @Input('info') plan?: TravelPlanShortModel;
}
