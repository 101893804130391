<div class="sidebar">
    <div class="logo">
        <img src="\assets\logos\Logo.png" alt="Logo">
    </div>
    <ul class="modules">
        <li class="align-items-center">
        <img src="\assets\icons\groups.svg" alt="groups">
            <a routerLink="/users" class="align-middle">Usuarios</a>
        </li>
    </ul>
    <button class="btn border-0" routerLink="/auth/login" (click)="logout()">
        <img src="\assets\icons\logout.svg" alt="logout">
        Cerrar Sesión
    </button>
</div>
