import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TravelPlansState } from '../reducers/travel-plans.reducer';




const selectTravelPlansState =
  createFeatureSelector<TravelPlansState>('travelPlans');

export class TravelPlansSelectors {


  static readonly selectPagination = createSelector(
      selectTravelPlansState,
      (state) => state.pagination
    );

  static readonly selectTravelPlans = createSelector(
      selectTravelPlansState,
      (state) => state.travelPlans
    );

  static readonly selectLoading = createSelector(
      selectTravelPlansState,
      (state) => state.loading
    );

    static readonly selectError = createSelector(
        selectTravelPlansState,
        (state) => state.error
      );
}
