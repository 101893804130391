<section class="navbar-section">
  <app-float-bar></app-float-bar>
</section>

<div class="container-block">
  <app-page-container page-title="Perfil" [breadcrumbs]="breadcrumb">
    <div class="text-center my-5 pt-5" *ngIf="!user">
      <div class="spinner-border text-dark" role="status">
        <span class="visually-hidden">Cargando...</span>
      </div>
    </div>

    <div class="container" *ngIf="user">
      <div class="container-profile">
        <div class="profile-row d-lg-flex flex-lg-wrap">
          <div class="col-lg-4 col-md-6 col-12 mb-3">
            <div class="item">Nombres</div>
            <div class="item-content">{{ user.firstName }}</div>
          </div>
          <div class="col-lg-4 col-md-6 col-12 mb-3">
            <div class="item">Apellidos</div>
            <div class="item-content">{{ user.lastName }}</div>
          </div>
          <div class="col-lg-4 col-md-6 col-12 mb-3">
            <div class="item">Fecha de nacimiento</div>
            <div class="item-content">{{ user.birthdate | date:'dd/MM/yyyy'}}</div>
          </div>
        </div>
        <div class="profile-row">
          <div class="col-lg-4 col-md-6 col-12 mb-3">
            <div class="item">Tipo de documento de identidad</div>
            <div class="item-content">{{ user.documentType?user.documentType.name:''}}</div>
          </div>
          <div class="col-lg-4 col-md-6 col-12 mb-3">
            <div class="item">Número de documento</div>
            <div class="item-content">{{ user.documentNumber }}</div>
          </div>
          <div class="col-lg-4 col-md-6 col-12 mb-3">
            <div class="item">Genero</div>
            <div class="item-content">{{ user.gender }}</div>
          </div>
        </div>
        <div class="profile-row">
          <div class="col-lg-4 col-md-6 col-12 mb-3">
            <div class="item">Correo</div>
            <div class="item-content">{{ user.email }}</div>
          </div>
          <div class="col-lg-4 col-md-6 col-12 mb-3">
            <div class="item">Departamento de residencia</div>
            <div class="item-content">{{ user.department }}</div>
          </div>
          <div class="col-lg-4 col-md-6 col-12 mb-3">
            <div class="item">Ciudad de residencia</div>
            <div class="item-content">{{ user.municipality }}</div>
          </div>
        </div>
        <div class="profile-row mb-4">
          <div class="col-lg-4 col-md-6 col-12 mb-3">
            <div class="item">Número de celular</div>
            <div class="item-content">{{ user.phoneNumber }}</div>
          </div>
        </div>
        <app-users-plans [(activeTab)]= "currentTab" [dataLengths]="lengthPlans"></app-users-plans>
        <app-plans-container [currentTab]="currentTab" [userId]="user.id" (dataLengths)="updateDataLengths($event)"></app-plans-container>
      </div>


    </div>

  </app-page-container>
</div>
