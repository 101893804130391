<table class="table table-striped" aria-describedby="Tabla de usuarios">
    <thead class="table-head">
        <tr>
            <th class="header-table" scope="col">
                <div class="d-flex gap-1">
                    <button [class.active]="currField === 'createdAt'" [class]="currDirection" (click)="sort('createdAt')">
                        <span class="cvu-icon cvu-icon-sorting"></span>
                    </button>
                    <span>Fecha de creacion</span>
                </div>
            </th>
            <th class="header-table" scope="col">
                <div class="d-flex gap-1">
                    <button [class.active]="currField === 'email'" [class]="currDirection" (click)="sort('email')">
                        <span class="cvu-icon cvu-icon-sorting"></span>
                    </button>
                    <span>Correo</span>
                </div>                
            </th>
            <th class="header-table" scope="col">
                <div class="d-flex gap-1">
                    <button [class.active]="currField === 'firstName'" [class]="currDirection" (click)="sort('firstName')">
                        <span class="cvu-icon cvu-icon-sorting"></span>
                    </button>
                    <span>Nombre</span>
                </div>
            </th>
            <th class="header-table" scope="col">
                <div class="d-flex gap-1">
                    <button [class.active]="currField === 'lastName'" [class]="currDirection" (click)="sort('lastName')">
                        <span class="cvu-icon cvu-icon-sorting"></span>
                    </button>
                    <span>Apellidos</span>
                </div>
            </th>
            <th class="header-table" scope="col"></th>
        </tr>
    </thead>
    <tbody>
        @for (user of (users$ | async); track user.id) {
            <tr>
                <td>{{ user.createdAt | date: 'dd/MM/yyyy'  }}</td>
                <td>{{ user.email }}</td>
                <td>{{ user.firstName }}</td>
                <td>{{ user.lastName }}</td>
                <td>
                    <button (click)="details(user.id)" class="d-flex gap-1 align-items-center">
                        <span class="cvu-icon cvu-icon-eye"></span>
                        <span>Ver detalle</span>
                    </button>
                </td>
            </tr>
        }
    </tbody>
</table>
