import { LatLng } from './latlng.model';
import { PlaceModel } from './place.model';
import { ProductModel, SavingsPlanModel } from './product.model';
import { UserModel } from './user.model';

export enum TravelPlanState {
  Draft = 'Draft',
  Active = 'Active',
  Finished = 'Finished',
}

export interface TravelPlanModel {
  id: string;
  user: UserModel;
  state: TravelPlanState;
  origin: LatLng;
  destination: PlaceModel;
  numberDays: number;
  numberPeople: number;
  numberContributions?: number;
  monthlyContributionPrice: number;
  approxPrice: number;
  products?: ProductModel[];
  createdAt: Date;
  updatedAt?: Date;
}

export interface TravelPlanShortModel {
  id: string;
  state: TravelPlanState;
  destination: PlaceModel;
  numberDays: number;
  numberPeople: number;
}

export interface TravelPlanSummaryModel {
  id: string;
  destination: PlaceModel;
  numberDays: number;
  numberPeople: number;
  numberContributions: number;
  monthlyContributionPrice: number;
  contributionsMade: number;
  totalSaving: number;
  totalBalance: number;
  nextContributionDate: string;
  savingsPlan: SavingsPlanModel;
}
