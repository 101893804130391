<div class="container-cards">
    <div class="cards-wrapper">
        <div *ngFor="let item of plan?.products; let i = index" class="accordion accordion-flush card"
        [id]="'accordioncard' + i">
        <div class="accordion-item ctn-card">
            <h2 class="accordion-header">
                <button class="accordion-button parent-accordion  cuotes" type="button" 
                    [attr.data-bs-target]="'#flush-collapseOne' + i" aria-expanded="true"
                    [attr.aria-controls]="'flush-collapseOne' + i"
                    style="padding: 0 0 10px 0; background-color: transparent;">
                    {{item.numberContributions}} cuotas
                </button>
            </h2>
            <div [id]="'flush-collapseOne' + i" class="accordion-collapse collapse show"
                [attr.data-bs-parent]="'#accordioncard' + i">
                <div class="accordion-body" style="padding: 0 0 0 0;">
                    <div class="row" style="margin-top: 16px;">
                        <div class="col">
                            <h1 class="save-text">Ahorro mensual</h1>
                            <h2 class="price">${{item.contributionPrice | currency : "COP" : "" : "1.0-0" :
                                "es-CO"}} COP
                            </h2>
                        </div>
                        <div class="col">
                            <h1 class="save-text">Ahorro diario</h1>
                            <h2 class="price">${{item.dailyPrice | currency : "COP" : "" : "1.0-0" :
                                "es-CO"}} COP</h2>
                        </div>
                    </div>
                    <div style="margin-bottom: 16px; margin-top: 8px;">
                        <h1 class="save-text">Ahorro estimado total<br> <span
                                class="pricetotal">${{item?.totalPrice | currency : "COP" : "" : "1.0-0" :
                                "es-CO"}} COP
                            </span>
                        </h1>
                    </div>
                    <div class="accordion accordion-flushtab" [id]="'accordiontable' + i">
                        <div class="accordion-itemtab child">
                            <h2 class="accordion-header">
                              <button (click)="toggleAccordion(i)" class="accordion-button collapsed text-table-deaits" type="button"
                              data-bs-toggle="collapse" [attr.data-bs-target]="'#flush-collapseTwo' + i" aria-expanded="false"
                              [attr.aria-controls]="'flush-collapseTwo' + i" style="padding: 0 0 0 0; background-color: transparent;">
                              {{ openAccordionIndex === i ? 'Ocultar tabla de anticipos' : 'Ver tabla de anticipos' }}
                          </button>


                            </h2>
                            <div [id]="'flush-collapseTwo' + i" class="accordion-collapse collapse"
                                [attr.data-bs-parent]="'#accordiontable' + i">
                                <div class="accordion-body" style="padding: 0 0 0 0;">

                              <app-table [product]="item"></app-table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion accordion-flushtab" style="margin-top: 10px;"
                        [id]="'accordionben' + i">
                        <div class="accordion-itemtab">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed text-table-deaits" type="button"
                                    data-bs-toggle="collapse"
                                    [attr.data-bs-target]="'#flush-collapseben' + i" aria-expanded="false"
                                    [attr.aria-controls]="'flush-collapseben' + i"
                                    style="padding: 0 0 0 0; background-color: transparent;">
                                    Beneficios adicionales
                                </button>
                            </h2>
                            <div [id]="'flush-collapseben' + i" class="accordion-collapse collapse"
                                [attr.data-bs-parent]="'#accordionben' + i">
                                <div class="accordion-body" style="padding: 0 0 0 0;">
                                    <div style="margin-top: 16px;">
                                        <!-- <h1 class="text-table-deaits" style="align-self: stretch;">Beneficios
                                        adicionales</h1> -->
                                    </div>
                                    <div class="d-flex">
                                        <img class="icn-check " src="\assets\icons\check.svg" />
                                        <h1 class="text-benefits ">Sorteo del valor total de tu viaje</h1>
                                    </div>
                                    <div class="d-flex">
                                        <img class="icn-check " src="\assets\icons\check.svg" />
                                        <h1 class="text-benefits ">Sorteo Viajas mientras ahorras: <br>
                                            <span class="title-back">2 noches para 2 personas</span>
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>

</div>
