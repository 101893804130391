import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PlanContractSummaryModel } from '@domain/models/plan-contract-summary.model';

@Component({
  selector: 'app-plan-card',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './plan-card.component.html',
  styleUrls: ['./plan-card.component.css'],
})
export class PlanCardComponent implements OnInit {
  @Input('info') plan!: PlanContractSummaryModel;
  percentagePaid: number = 0;
  isPending: boolean = false;
  @Input() currentTab: string = 'active';

  ngOnInit(): void {
    if (this.plan?.nextContributionDate) {
      this.percentagePaid = this.calcPercentagePaid();
      this.isPending = new Date(this.plan.nextContributionDate) < new Date();
    } else {
      console.error('Plan is undefined or nextContributionDate is missing.');
    }
  }

  calcPercentagePaid(): number {
    if (this.plan && this.plan.numberContributions !== 0) {
      return Math.round(
        (this.plan.contributionsMade / this.plan.numberContributions) * 100
      );
    } else {
      return 0; // O cualquier otro valor predeterminado que desees
    }
  }
}
