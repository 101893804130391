<div class="plans-container container mt-4">
    <div *ngIf="currentTab === 'active'">
        <div class="d-flex flex-row gap-3 mw-100 w-fit-content mx-auto pb-3 overflow-x-auto">
            <div *ngFor="let plan of data.actives">
                <app-plan-card ngif [info]="plan"></app-plan-card>
            </div>
        </div>
        <span class="text-center my-5 fw-bold fs-5 d-block" *ngIf="!isLoading && data.actives.length == 0">
            <img style="margin-bottom: 30px;" src="\assets\icons\no-items.svg" alt="Sin resultados"> <br>
            El usuario no tiene planes activos hasta el momento
        </span>
    </div>

    <div *ngIf="currentTab === 'draft'">
        <div class="plan-list d-grid w-100 pb-3">
            @for (item of data.drafts; track $index) {
                <app-plan-list-item [info]="item"></app-plan-list-item>
            }
        </div>
        <span class="text-center my-5 fw-bold fs-5 d-block" *ngIf="!isLoading && data.drafts == null">
            <img style="margin-bottom: 30px;" src="\assets\icons\no-items.svg" alt="Sin resultados"> <br>
            El usuario no tiene planes borradores hasta el momento
        </span>
    </div>
    <div *ngIf="currentTab === 'finished'">
        <div class="d-flex flex-row gap-3 mw-100 w-fit-content mx-auto pb-3 overflow-x-auto">
            <div *ngFor="let plan of data.finished">
                <app-plan-card [info]="plan" [currentTab]="currentTab"></app-plan-card>
            </div>
        </div>
        <span class="text-center my-5 fw-bold fs-5 d-block" *ngIf="!isLoading && data.finished.length == 0">
            <img style="margin-bottom: 30px;" src="\assets\icons\no-items.svg" alt="Sin resultados"> <br>
            El usuario no tiene planes finalizados hasta el momento
        </span>
    </div>
    <div class="text-center" *ngIf="isLoading">
        <div class="spinner-border text-dark" role="status">
            <span class="visually-hidden">Cargando...</span>
        </div>
    </div>
</div>
