<div class="plan-card px-3 py-4 rounded-4">
    <div class="plan-card-header mb-2">
        <h6 class="m-0 fw-normal">Plan de ahorro</h6>
        <h5 class="fw-bold m-0">{{ plan.customName }}</h5>
    </div>
    <div class="plan-card-body mb-2">
        <div>
            <span>
                {{ plan.contributionsMade }} de {{ plan.numberContributions }} aportes realizados
            </span>
            <div class="progress rounded-6 my-2">
                <div class="progress-bar" role="progressbar" [style.width]="percentagePaid + '%'"
                    [attr.aria-valuenow]="percentagePaid" aria-valuemin="0" aria-valuemax="100">
                </div>
            </div>
            <div class="d-flex justify-content-between">
                <div>
                    <span>Total abonado</span>
                    <p class="m-0">
                        <span>$</span>
                        <span class="fw-bold">{{ plan.valueSaved | currency : "COP" : "" : "1.0-0" : "es-CO" }} </span>
                        <span>COP</span>
                    </p>
                </div>
                <div class="text-end">
                    <span>Saldo pendiente</span>
                    <p class="m-0">
                        <span>$</span>
                        <span class="fw-bold">{{ (plan.planValue - plan.valueSaved) | currency : "COP" : "" : "1.0-0" : "es-CO" }} </span>
                        <span>COP</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="plan-card-footer" *ngIf="currentTab !== 'finished'">
        <p class="text-center m-0 mt-2" [class.pending-contribution]="isPending">
            <span *ngIf="isPending">Aporte pendiente desde: </span>
            <span *ngIf=""></span>
            <span *ngIf="!isPending">Próximo aporte: </span>
            <span class="fw-semi-bold">{{ plan.nextContributionDate | date : 'dd/MM/yyyy' }}</span>
        </p>
    </div>
</div>
