import { PaginatedData } from '@base/wrappers/paginated-data';
import { TravelPlanState, TravelPlanShortModel } from '@domain/models/travel-plan.model';
import { createAction, props } from '@ngrx/store';


export const loadTravelPlans = createAction(
  '[Travel Plans] Load Plans',
  props<{
    userId:string,
    state: TravelPlanState;
    pageNumber?: number;
    pageSize?: number;
  }>()
);

export const loadTravelPlansSuccess = createAction(
  '[Travel Plans] Load Plans Success',
  props<{ pagination: PaginatedData<TravelPlanShortModel> }>()
);

export const loadTravelPlansFailure = createAction(
  '[Travel Plans] Load Plans Failure',
  props<{ error: any }>()
);

export const clearTravelPlans = createAction('[Travel Plans] Clear Plan List');
