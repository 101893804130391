<div class="container-table-advance">



    <nav>
      <a >Aporte</a>
      <a >Disponible</a>
      <div class="animation"></div>
    </nav>

  <div class="text-center my-5 pt-5" *ngIf="advancesAvailable.length==0">
    <div class="spinner-border text-dark" role="status">
        <span class="visually-hidden">Cargando...</span>
    </div>
  </div>
  <div class="movements-list" *ngIf="advancesAvailable.length >0">

    <div
      class="item"
      *ngFor="let item of advancesAvailable; let i = index"
    >
    <nav>
      <a >{{item.numberContribution}}</a>
      <a > ${{
        item.amount
          | currency : "COP" : "" : "1.0-0" : "es-CO"
      }}</a>
    </nav>

  </div>

</div>
