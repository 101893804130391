import { PaginatedData } from '@base/wrappers/paginated-data';
import { UseCase } from '@domain/base/use-case';
import { PlanContractSummaryModel } from '@domain/models/plan-contract-summary.model';
import { TravelPlanState } from '@domain/models/travel-plan.model';
import { PlanContractRepository } from '@domain/repositories/plan-contract.repository';
import { Observable } from 'rxjs';

export class GetPlansContractSummaryUseCase
  implements
    UseCase<
      {
        userId: string;
        state: TravelPlanState;
        pageNumber?: number;
        pageSize?: number;
      },
      PaginatedData<PlanContractSummaryModel>
    >
{
  constructor(private planContractRepository: PlanContractRepository) {}

  execute(params: {
    userId: string;
    state: TravelPlanState;
    pageNumber?: number;
    pageSize?: number;
  }): Observable<PaginatedData<PlanContractSummaryModel>> {
    return this.planContractRepository.getPlansSummary(
      params.userId,
      params.state,
      params.pageNumber ?? 1,
      params.pageSize ?? 100
    );
  }
}
