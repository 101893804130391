import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { UsersPlansSummaryActions } from '../../../../core/store/actions/users-plans-summary.actions';
import { UsersPlansSummarySelectors } from '../../../../core/store/selectors/users-plans-summary.selectors';
import {
  UserPlansSummaryFilters,
  UserPlansSummaryModel,
} from '@domain/models/user.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-users-table',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './users-table.component.html',
  styleUrl: './users-table.component.scss',
})
export class UsersTableComponent {
  users$: Observable<UserPlansSummaryModel[]>;
  isLoading$: Observable<boolean>;

  private filters?: UserPlansSummaryFilters;
  private filtersSubscription: Subscription;

  currField?: keyof UserPlansSummaryModel;
  currDirection: 'asc' | 'desc' = 'asc';

  constructor(private store: Store,  private router: Router,) {
    this.users$ = this.store.select(UsersPlansSummarySelectors.selectUsers);
    this.isLoading$ = this.store.select(
      UsersPlansSummarySelectors.selectLoading
    );

    const filters$ = this.store.select(
      UsersPlansSummarySelectors.selectFilters
    );

    this.filtersSubscription = filters$.subscribe((filters) => {
      this.filters = filters;
    });
  }

  ngOnDestroy(): void {
    this.filtersSubscription.unsubscribe();
  }

  sort(field: string): void {
    if (
      !this.isValidPropertyName<UserPlansSummaryModel>(
        field as keyof UserPlansSummaryModel
      )
    ) {
      return;
    }

    if (this.currField === field && this.currDirection === 'asc') {
      this.currDirection = 'desc';
    } else if (this.currField === field && this.currDirection === 'desc') {
      this.currDirection = 'asc';
      this.currField = undefined;
    } else {
      this.currDirection = 'asc';
      this.currField = field as keyof UserPlansSummaryModel;
    }

    const filters: UserPlansSummaryFilters = {
      ...this.filters,
      pageNumber: 1,
      pageSize: this.filters?.pageSize ?? 5,
      sortBy: this.currField,
      sortOrder: this.currDirection,
    };

    this.loadUsers(filters);
  }

  private loadUsers(filters: UserPlansSummaryFilters) {
    this.store.dispatch(
      UsersPlansSummaryActions.loadUsers({
        filters,
      })
    );
  }

  private isValidPropertyName<T>(
    propertyName: keyof T
  ): propertyName is keyof T {
    return true;
  }

  details(userId: string) {
    this.router.navigate(['users/details', userId]);    
  }
  
}
